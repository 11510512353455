<template>
    <div class="app-container">

        <div class="filter-container">
            <h3>Learning modules</h3>
            <!--<el-input style="flex: 1" class="item" :placeholder="'Search learning modules'" v-model="search"/>-->
            <el-button class="module-add item" type="primary" @click="newModule">Add module</el-button>
        </div>

        <el-table-draggable handle=".handle" @drop="dragModule" v-loading="loading">
          <el-table :data="modules">
            <el-table-column width="30">
              <template slot-scope="scope">
                <i class="handle el-icon-d-caret" />
              </template>
            </el-table-column>

            <el-table-column label="Module title">
              <template slot-scope="scope">
                <div>
                  {{scope.row.title}}
                </div>
              </template>
            </el-table-column>

            <el-table-column align="center" width="70">
              <template slot-scope="scope">
                <el-button type="primary" icon="el-icon-edit" size="small" @click="viewModule(scope.row)"></el-button>
              </template>
            </el-table-column>

          </el-table>
        </el-table-draggable>

    </div>
</template>

<script>
    import ElTableDraggable from 'element-ui-el-table-draggable';
    import _ from 'lodash';
    import axios from 'axios';
    import properties from '@/utils/properties';
    import auth from '@/utils/auth';

    export default {
        name: 'modules',
        components: {
          ElTableDraggable,
        },
        data() {
            return {
                modules: [],
                loading: true,
                search: '',
                debouncedSearch: '',
            };
        },
        async mounted() {
            this.getModules();
        },
        watch: {
          search(value) {
            this.searchModules(value);
          },
        },
        methods: {
            async getModules(page) {
              const token = await this.$auth.getTokenSilently();

              this.loading = true;

              var query = `${process.env.VUE_APP_BASE_URI}/learning`;

              axios.get(query,{
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              })
              .then(response => {
                  const data = response.data.data;
                  var modules = [];
                  if(data && data.modules) {
                    data.modules.forEach(module => {
                      modules.push({ ...module });
                    });
                  }
                  this.modules = modules;
                  this.loading = false;
                  console.log("Got modules:",this.modules);

              })
              .catch((error) => {
                  this.modules = [];
                  this.loading = false;
                  console.log("API error",error,error.response);
              });

            },

            searchModules: _.debounce(function(value) {
              this.debouncedSearch = value;
              this.getModules();
            },500),

            newModule() {
              this.$router.push(`/module/new`)
            },

            viewModule(org) {
              this.$router.push(`/module/${org.id}`)
            },

            async dragModule(data) {
              const order = { ids: data.list.map(module => { return module.id; }) };
              const token = await this.$auth.getTokenSilently();
              
              axios.put(`${process.env.VUE_APP_BASE_URI}/learning/order`,
                order,
                {
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                }
              )
              .catch((error) => {
                  console.log("API error: update module order",error,error.response);
              });
            },
        },
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

    .pagination-container {
        background-color: white;
        margin: 0;
        padding: 20px;
        border: 1px solid #EBEEF5;
        border-top: 0;
        display: flex;
        justify-content: center;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .filter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        h3 {
          flex: 1;
          margin: 0 0 10px;
        }

        .new-user {
            float: right;
        }

        .outcome-filter, .resource-filter {
          margin-left: 10px;
        }
    }

    .full-width {
        width: 100%;
    }

    .module-add {
      margin-left: 10px;
    }

    .outcome, .resource {
      margin-right: 4px;
    }

    /deep/ .el-table__row {
      cursor: pointer;
    }

    /deep/ .el-pagination {
      text-align: center;
      button, li {
        background: transparent;
      }
    }

</style>
