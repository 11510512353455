var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("h3", [_vm._v("Learning modules")]),
          _c(
            "el-button",
            {
              staticClass: "module-add item",
              attrs: { type: "primary" },
              on: { click: _vm.newModule },
            },
            [_vm._v("Add module")]
          ),
        ],
        1
      ),
      _c(
        "el-table-draggable",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { handle: ".handle" },
          on: { drop: _vm.dragModule },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.modules } },
            [
              _c("el-table-column", {
                attrs: { width: "30" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", { staticClass: "handle el-icon-d-caret" }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Module title" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(" " + _vm._s(scope.row.title) + " "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-edit",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewModule(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }