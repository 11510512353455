import {validateRoute} from '@/utils/validate';

export default {
    user: null,
    token: null,

    login(username,password) {

      // MOCKED LOGIN
      return new Promise((resolve, reject) => {
        if(username == "admin@codefishstudio.com" && password == "test123") {
          this.setUser({id:1,name:"admin"});
          resolve(this.user);
        }
        else {
          reject("Incorrect user or password");
        }
      });
    },

    logout() {

      // MOCKED LOGIN
      return new Promise(resolve => {
        this.user = null;
        resolve(true);
      });
    },

    authenticated() {

        // MOCKED AUTHENTICATION CHECK
        return new Promise(resolve => {
            resolve(this.user);
        }).then(user => {
            return !!user;
        });

    },

    setUser(user) {
        this.user = user;
    },

    // TEMP: get tokan from auth0
    setToken(token) {
      console.log("Auth token set:",token);
      this.token = token;
    },
    getToken() {
      if(!this.token) {
        return process.env.VUE_APP_TEMP_AUTH_TOKEN;
      }
      return this.token;
    },

};
